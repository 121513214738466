import React, { useState, useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import { Formik, Form } from "formik"
import classNames from "classnames"

import AuthForm from "./AuthForm"
import Layout from "layout"
import Button from "elements/Button"
import Message from "elements/Message"
import Container from "layout/Container"

import { authValidationSchema } from "./utils/authValidationSchema"
import { AppContext } from "context/AppContext"
import { isBrowser } from "services/general"
import { handleAuthSubmit } from "./services/auth"
import { hasSignedInUser } from "./services/user"

const Auth = (props) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const { state, dispatch } = useContext(AppContext)

  let { pageContext, location } = props
  let { module } = pageContext

  const handleSubmit = (values, { resetForm }) => {
    setMessage({})
    setLoading(true)

    let payload = {
      values,
      resetForm,
      setMessage,
      setLoading,
      module: module.name,
    }
    handleAuthSubmit({ payload, state, dispatch, location })
  }

  useEffect(() => {
    if (isBrowser()) {
      if (hasSignedInUser()) navigate("/")

      if (!state.auth.hasBeenVerified && module.name === "sign-up")
        navigate("/verify-email")
      if (state.auth.hasBeenVerified && module.name === "sign-in") {
        setMessage({
          type: "danger",
          content:
            "This email is already a registered MedGrocer account. Please sign in using these credentials.",
        })

        dispatch({ type: "RESET_DETAILS" })
      }
    }
  }, [state.auth.hasBeenVerified, module.name, dispatch])

  return (
    <Layout
      title={module.title}
      subtitle={module.subtitle}
      seoTitle={module.title}
    >
      <Container isCentered>
        <Formik
          initialValues={state.auth}
          validationSchema={authValidationSchema(module.name)}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <AuthForm module={module.name} values={values} />
              {module.name === "sign-in" && (
                <p className="is-size-6 pb-1 pt-0">
                  <Link to="/forgot-password">Forgot password</Link>
                </p>
              )}
              {message?.content && (
                <Message color={message?.type}>
                  {message?.content?.message || message?.content}
                </Message>
              )}
              <Button
                type="submit"
                color="primary"
                disabled={loading}
                className={classNames({ "is-loading": loading })}
                isFullwidth
              >
                {module.cta}
              </Button>
              {module.name === "sign-in" && (
                <section>
                  <p className="has-text-centered is-size-6 pt-1">
                    Don't have an account yet?{" "}
                    <Link to="/verify-email">Enroll Now.</Link>
                  </p>
                </section>
              )}
              {module.name !== "sign-in" && (
                <section>
                  <p className="has-text-centered is-size-7 pt-1">
                    By signing up, you agree to our{" "}
                    <Link to={`/mind/terms-and-conditions`}>
                      Terms and Conditions
                    </Link>{" "}
                    and <Link to={`/mind/privacy-policy`}>Privacy Policy</Link>
                  </p>
                  <p className="has-text-centered is-size-6 mt-2">
                    Already have an account? <Link to="/sign-in">Sign In.</Link>
                  </p>
                </section>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default Auth
