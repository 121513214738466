import { authFormFields } from "./utils/authFormFields"
import { generateFormField } from "elements/Form/services/form"

const AuthForm = ({ module, values }) => {
  let formFields = authFormFields(values)

  return formFields
    .filter((formField) => {
      return formField.inclusions.includes(module)
    })
    .map((formField) => {
      if (!formField?.referenceAnswer) {
        return generateFormField({
          formField,
          values,
          formFields: formFields,
        })
      }
      return null
    })
}

export default AuthForm
